/**
 * Nosy 404 Page Template
 *
 * @export Page
 */
import { Box, Button, Center, Container, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import AnimatedNosyMark from '../components/animated-nosy-mark/animated-nosy-mark'
import Layout from '../components/layout/layout'
import Link from '../components/link/link'
import SEO from '../components/seo/seo'

const NotFoundPage = () => (
    <Layout>
        <SEO title='404 Not found' />
        <Container 
            centerContent
            maxWidth='6xl' 
            zIndex={2} 
            position='relative'>
            <Stack 
                direction='column'
                spacing={12}>
                <Heading 
                    textAlign='center'
                    as='h1'
                    maxWidth='5xl'
                    fontSize={[ '3xl', '4xl', '6xl','8xl' ]}
                    my={12} 
                    fontWeight='extrabold'>
                    Page Not Found
                </Heading>
                <Box>
                    <Box
                        // bg={['red.500', 'orange.500', 'yellow.500', 'green.500', 'teal.500']}
                        display='block'
                        height={['60vh', '65vh', '70vh', '75vh', '80vh']}
                        width='full'
                        maxWidth='100%'
                        position='absolute'
                        top={['-25vh', '-35vh', '-40vh', '-35vh']}
                        left={0}
                        zIndex={-1}>
                        <AnimatedNosyMark />
                    </Box>
                </Box>
                <Box>
                    <Center>
                        <Text 
                            mx='auto'
                            fontSize={['md', 'lg', 'xl']}
                            maxWidth='2xl'
                            textAlign='center'
                            mb={10}>
                        The way is shut. Then they halted and looked at him and saw that he lived still; but he did not look at them. The way is shut, his voice said again. It was made by those who are Dead, and the Dead keep it, until the time comes.
                        </Text>
                    </Center>
                    <Center>
                        <Text 
                            mx='auto'
                            fontSize={['md', 'lg', 'xl']}
                            maxWidth='2xl'
                            textAlign='center'
                            mb={10}>The way is shut
                        </Text>
                    </Center>
                </Box>
                <Center>
                    <Button
                        as={Link}
                        to='/'
                        size='lg'
                        variant='brand-outline'>
                    Home
                    </Button>
                </Center>

            </Stack>
        </Container>
    </Layout>
)

export default NotFoundPage
